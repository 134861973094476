<script>
import { roleService } from '../../../helpers/backend/role.service';
import { userService } from "../../../helpers/backend/user.service";
export default {
  data() {
    return {
      form: {
        id: null,
        name: null,
        user_role: null,
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
      roleOptions: [{ text: "Select One", value: null }]
    };
  },
  created(){
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.busy = true;
      userService.updateUserRole(this.form).then(
        (data) => {
          if (data != null) {
            this.reset();
            this.$emit("refresh");
            this.showDismissibleAlert = true;
            this.error = "";
            this.setActive(false);
          }
          this.busy = false;
        },
        (error) => {
          this.showDismissibleAlert = true;
          this.error =
            "Failed to Insert/Update Role. Error : " + error;
          this.busy = false;
        }
      );
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.name = "";
      this.form.user_role = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onReset(event) {
      event.preventDefault();
      this.setActive(false);
      this.reset();
    },
    setActive(b, outside_triger) {
      this.active = b;
      if (outside_triger) return;
      this.$emit("active_change", b);
    },
    initUpdate(id) {
      this.setActive(true);

      this.$nextTick(() => {
        this.reset();
        this.$refs.name.$el.focus();
        this.busy = true;
        userService.getById(id).then(
          (data) => {
            if (data.user != null) {
              this.form.id = data.user.id;
              this.form.name = data.user.name;
              this.form.user_role = data.user.user_role;
            } else {
              this.showDismissibleAlert = true;
              this.error =
                "Failed to get user type to update. Error : user not found";
            }
            this.busy = false;
          },
          (err) => {
            this.showDismissibleAlert = true;
            this.error = "Failed to get user type to update. Error : " + err;
            this.busy = false;
          }
        );
      });
    },
    invokeCreate() {
      this.setActive(true);
    },
    fetchData(){
      roleService.getAll().then(
        (data) => {
          this.roleOptions = this.roleOptions.concat(
            data.roles.map(function (mType) {
              return { text: mType.name, value: mType.id }
            })
          )
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get role. Error : " + err
        }
      )
    },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="User Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="User Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="User Role:"
                label-for="input-2"
              >
                <!-- <b-form-select
                  id="input-2"
                  v-model="form.role"
                  :options="roleOptions"
                  required
                ></b-form-select> -->
                <!-- <b-form-input
                  id="input-2"
                  v-model="form.user_role"
                  type="text"
                  placeholder="User Role"
                  required
                ></b-form-input> -->
                <b-form-select
                  id="input-2"
                  v-model="form.user_role"
                  :options="roleOptions"
                  required
                ></b-form-select>
              </b-form-group>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div> -->
</template>



